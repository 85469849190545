import React, { forwardRef } from 'react';
import cn from 'classnames';

// Import icons as React components, but with a custom setup, not SVGR. This produces small wrapper
// components that load the actual image contents with `<use>` so that SVGs are loaded as SVGs, not
// JS (faster). There's nothing special about Icon.tsx now, you can do this anywhere!
import Chromebook from './icons/Chromebook.svg?react';
import DesktopComputer from './icons/Desktop computer.svg?react';
import InteractiveWhiteboard from './icons/Interactive whiteboard.svg?react';
import Laptop from './icons/laptop.svg?react';
import LpLogo from './icons/Lp-logo.svg?react';
import LpLogoDark from './icons/Lp-logo-dark.svg?react';
import MobileDevice from './icons/Mobile device.svg?react';
import Tablet from './icons/tablet.svg?react';
import Accept from './icons/accept.svg?react';
import Accesscontrols from './icons/instUI/accesscontrols-icon.svg?react';
import Accessibility from './icons/accessibility.svg?react';
import AccountCircle from './icons/account-circle.svg?react';
import Add from './icons/add.svg?react';
import AddToLibrary from './icons/add-to-library.svg?react';
import AddImage from './icons/addImage.svg?react';
import AddPhotoAlternate from './icons/addPhotoAlternate.svg?react';
import AddPhotoPreview from './icons/addPhotoPreview.svg?react';
import Admins from './icons/instUI/admins-icon.svg?react';
import Apple from './icons/apple.svg?react';
import AppleLogo from './icons/apple-logo.svg?react';
import ApplicationExtension from './icons/application-extension.svg?react';
import Archive from './icons/archive.svg?react';
import ArchiveIcon from './icons/archive-icon.svg?react';
import ArrowUpDown from './icons/arrow-up-down.svg?react';
import Assessment from './icons/assessment.svg?react';
import Authentication from './icons/authentication.svg?react';
import BackIcon from './icons/back-icon.svg?react';
import Badges from './icons/badges.svg?react';
import Blog from './icons/blog.svg?react';
import BrandContainer from './icons/brand-container.svg?react';
import BusinessCenter from './icons/business-center.svg?react';
import CanvasIntegrated from './icons/canvas-integrated.svg?react';
import CanvasConnect from './icons/canvas-connect.svg?react';
import Calendar from './icons/calendar.svg?react';
import Card from './icons/card.svg?react';
import ChartBarSolid from './icons/chartBarSolid.svg?react';
import CheckIcon from './icons/check-icon.svg?react';
import CheckRound from './icons/check-round.svg?react';
import CheckFill from './icons/checkFill.svg?react';
import CheckRound2 from './icons/checkRound.svg?react';
import ChevronDown from './icons/chevron-down.svg?react';
import ChevronUp from './icons/chevron-up.svg?react';
import ChipClose from './icons/chipClose.svg?react';
import Circle from './icons/circle.svg?react';
import ClasslinkLogo from './icons/classlink-logo.svg?react';
import Clear from './icons/clear.svg?react';
import ClearSearch from './icons/clearSearch.svg?react';
import CleverLogo from './icons/clever-logo.svg?react';
import Clock from './icons/clock.svg?react';
import Close from './icons/close.svg?react';
import CloseButton from './icons/closeButton.svg?react';
import Collapse from './icons/collapse.svg?react';
import Columns from './icons/columns.svg?react';
import Comment from './icons/comment.svg?react';
import CommentHide from './icons/commentHide.svg?react';
import CommentShow from './icons/commentShow.svg?react';
import Compare from './icons/compare.svg?react';
import CompareIcon from './icons/compare-icon.svg?react';
import Completed from './icons/completed.svg?react';
import Congratulations from './icons/congratulations.svg?react';
import Construction from './icons/construction.svg?react';
import Content from './icons/content.svg?react';
import Copy from './icons/copy.svg?react';
import CopyUrl from './icons/copyUrl.svg?react';
import CreateCompany from './icons/createCompany.svg?react';
import Cube from './icons/cube.svg?react';
import DangerFill from './icons/dangerFill.svg?react';
import Data from './icons/data.svg?react';
import DataIcon from './icons/dataIcon.svg?react';
import DataSourceCs from './icons/dataSourceCs.svg?react';
import DataSourceIste from './icons/dataSourceIste.svg?react';
import DataSourceJamf from './icons/dataSourceJamf.svg?react';
import DataSourceNces from './icons/dataSourceNces.svg?react';
import DataSourcePu from './icons/dataSourcePu.svg?react';
import DataSourceSdpc from './icons/dataSourceSdpc.svg?react';
import DataSourceSpp from './icons/dataSourceSpp.svg?react';
import DateQuestionIcon from './icons/date-question-icon.svg?react';
import Decline from './icons/decline.svg?react';
import Desktop from './icons/desktop.svg?react';
import Display from './icons/instUI/display-icon.svg?react';
import Districts from './icons/districts.svg?react';
import Doc from './icons/doc.svg?react';
import Done from './icons/done.svg?react';
import Donut from './icons/donut.svg?react';
import Download from './icons/instUI/download.svg?react';
import DragdropHorizontal from './icons/dragdrop-horizontal.svg?react';
import Dragndrop from './icons/dragndrop.svg?react';
import DsCalendar from './icons/ds-calendar.svg?react';
import DsMytasks from './icons/ds-mytasks.svg?react';
import DsRemove from './icons/ds-remove.svg?react';
import DsTooltip from './icons/ds-tooltip.svg?react';
import Duplicate from './icons/duplicate.svg?react';
import Edit from './icons/edit.svg?react';
import Edit2 from './icons/instUI/edit2.svg?react';
import Educators from './icons/educators.svg?react';
import Edu from './icons/edu.svg?react';
import EllipsisIcon from './icons/ellipsis-icon.svg?react';
import Email from './icons/email.svg?react';
import EmptyState from './icons/emptyState.svg?react';
import EmptyState10 from './icons/emptyState10.svg?react';
import EmptyState11 from './icons/emptyState11.svg?react';
import EmptyState2 from './icons/emptyState2.svg?react';
import EmptyState3 from './icons/emptyState3.svg?react';
import EmptyState4 from './icons/emptyState4.svg?react';
import EmptyState5 from './icons/emptyState5.svg?react';
import EmptyState6 from './icons/emptyState6.svg?react';
import EmptyState7 from './icons/emptyState7.svg?react';
import EmptyStateLaunch from './icons/emptyStateLaunch.svg?react';
import EmptyStateNoLinks from './icons/emptyStateNoLinks.svg?react';
import EssaLevel from './icons/instUI/essa-level-1.svg?react';
import Event from './icons/event.svg?react';
import ExistingCompany from './icons/existingCompany.svg?react';
import ExpandLess from './icons/expandLess.svg?react';
import ExpandMore from './icons/expandMore.svg?react';
import Export from './icons/export.svg?react';
import ExternalLink from './icons/externalLink.svg?react';
import Eye from './icons/eye.svg?react';
import Facebook from './icons/facebook.svg?react';
import FacebookLogo from './icons/facebook-logo.svg?react';
import FailedIcon from './icons/failedIcon.svg?react';
import FastForward from './icons/fast-forward.svg?react';
import FeedbackIcon from './icons/feedback-icon.svg?react';
import File from './icons/file.svg?react';
import FileExtension from './icons/file-extension.svg?react';
import FileOutline from './icons/file-outline.svg?react';
import FileUploadQuestionIcon from './icons/file-upload-question-icon.svg?react';
import Filter from './icons/filter.svg?react';
import FilterClose from './icons/filterClose.svg?react';
import ForgotPassword from './icons/forgotPassword.svg?react';
import Forms from './icons/instUI/forms-icon.svg?react';
import FormsOutline from './icons/forms-outline.svg?react';
import FreeTextQuestionIcon from './icons/free-text-question-icon.svg?react';
import Gdrive from './icons/gdrive.svg?react';
import Generic from './icons/generic.svg?react';
import Gift from './icons/gift.svg?react';
import Google from './icons/google.svg?react';
import Grade from './icons/grade.svg?react';
import GraduationCap from './icons/graduation-cap.svg?react';
import Groups from './icons/groups.svg?react';
import GsuiteLogo from './icons/gsuite-logo.svg?react';
import Hardware from './icons/hardware.svg?react';
import HourglassIcon from './icons/hourglass-icon.svg?react';
import Ignored from './icons/ignored.svg?react';
import Image from './icons/image.svg?react';
import Impersonate from './icons/impersonate.svg?react';
import Incompleted from './icons/incompleted.svg?react';
import Info from './icons/info.svg?react';
import InfoItalic from './icons/info-italic.svg?react';
import InfoBorderless from './icons/info-borderless.svg?react';
import InstDownload from './icons/inst-download.svg?react';
import InstEdit from './icons/inst-edit.svg?react';
import InstEyeFilled from './icons/instUI/eye-filled.svg?react';
import InstEyeLine from './icons/inst-eye-line.svg?react';
import EyeLineSlash from './icons/eye-line-slash.svg?react';
import InstNotepad from './icons/inst-notepad.svg?react';
import InstTrash from './icons/inst-trash.svg?react';
import InstInfo from './icons/inst-info.svg?react';
import InstGradeBook from './icons/instUI/gradebook.svg?react';
import InstFile from './icons/instUI/file.svg?react';
import Invitation from './icons/invitation.svg?react';
import Jpg from './icons/jpg.svg?react';
import KeyboardArrowDown from './icons/keyboard-arrow-down.svg?react';
import KeyboardArrowLeft from './icons/keyboard-arrow-left.svg?react';
import KeyboardArrowRight from './icons/keyboard-arrow-right.svg?react';
import KeyboardArrowUp from './icons/keyboard-arrow-up.svg?react';
import LaptopMac from './icons/laptop-mac.svg?react';
import Launch from './icons/launch.svg?react';
import LeftarrowIcon from './icons/leftarrow-icon.svg?react';
import LikertScaleQuestionIcon from './icons/likert-scale-question-icon.svg?react';
import List from './icons/list.svg?react';
import Lms from './icons/lms.svg?react';
import Lock from './icons/lock.svg?react';
import Locked from './icons/locked.svg?react';
import Logo from './icons/logo.svg?react';
import LogoCircle from './icons/logo-circle.svg?react';
import LogoSolid from './icons/logo-solid.svg?react';
import LogoWhite from './icons/logo-white.svg?react';
import LpLogoColor from './icons/lpLogoColor.svg?react';
import LpLogoBlack from './icons/learn_platform.svg?react';
import Mail from './icons/mail.svg?react';
import Mapped from './icons/mapped.svg?react';
import Mapper from './icons/mapper.svg?react';
import Mapperhub from './icons/mapperhub.svg?react';
import Marketplace from './icons/marketplace.svg?react';
import MarkRound from './icons/markRound.svg?react';
import Memberships from './icons/instUI/memberships-icon.svg?react';
import MenuAnalysis from './icons/menu-analysis.svg?react';
import MenuCommunity from './icons/menu-community.svg?react';
import MenuDashboard from './icons/menu-dashboard.svg?react';
import MenuData from './icons/menu-data.svg?react';
import MenuGear from './icons/menu-gear.svg?react';
import MenuHome from './icons/menu-home.svg?react';
import MenuManagement from './icons/menu-management.svg?react';
import MenuMembers from './icons/menu-members.svg?react';
import MenuProducts from './icons/menu-products.svg?react';
import MenuSettings from './icons/menu-settings.svg?react';
import MenuSubscriptions from './icons/menu-subscriptions.svg?react';
import MenuSysadmin from './icons/menu-sysadmin.svg?react';
import MicrosoftLogo from './icons/microsoft-logo.svg?react';
import Minus from './icons/minus.svg?react';
import MissingProduct from './icons/missing-product.svg?react';
import MoreHoriz from './icons/more-horiz.svg?react';
import MultiSelectQuestionIcon from './icons/multi-select-question-icon.svg?react';
import MultipleProducts from './icons/multiple-products.svg?react';
import NewProducts from './icons/newProducts.svg?react';
import NewwindowIcon from './icons/newwindow-icon.svg?react';
import NoIconPlaceholder from './icons/no-icon-placeholder.svg?react';
import NotMapped from './icons/not-mapped.svg?react';
import NotMappedDisabled from './icons/not-mapped-disabled.svg?react';
import Notes from './icons/notes.svg?react';
import NotificationBell from './icons/notification-bell.svg?react';
import Operational from './icons/operational.svg?react';
import Organizations from './icons/organizations.svg?react';
import Other from './icons/other.svg?react';
import PaperClip from './icons/paper-clip.svg?react';
import PaperPlane from './icons/paper-plane.svg?react';
import Pdf from './icons/pdf.svg?react';
import People from './icons/instUI/people.svg?react';
import Pin from './icons/pin.svg?react';
import PlaceholderQuestionIcon from './icons/placeholder-question-icon.svg?react';
import Plus from './icons/plus.svg?react';
import Plug from './icons/plug.svg?react';
import PlusRound from './icons/plusRound.svg?react';
import Png from './icons/png.svg?react';
import Ppl from './icons/ppl.svg?react';
import PricingQuestionIcon from './icons/pricing-question-icon.svg?react';
import ProductLogoPlaceholder from './icons/product-logo-placeholder.svg?react';
import ProductCustomPrivacyStatus from './icons/productCustomPrivacyStatus.svg?react';
import ProductCustomStatus from './icons/productCustomStatus.svg?react';
import Products from './icons/products.svg?react';
import Providers from './icons/providers.svg?react';
import Public from './icons/public.svg?react';
import QuickReferenceAll from './icons/quick-reference-all.svg?react';
import RatingDigitsScaleQuestionIcon from './icons/rating-digits-scale-question-icon.svg?react';
import Reference from './icons/reference.svg?react';
import Refresh from './icons/refresh.svg?react';
import Remind from './icons/instUI/remind.svg?react';
import RemoveIcon from './icons/remove-icon.svg?react';
import RemoveRedEye from './icons/removeRedEye.svg?react';
import Reply from './icons/reply.svg?react';
import Resources from './icons/resources.svg?react';
import Revert from './icons/revert.svg?react';
import RightarrowIcon from './icons/rightarrow-icon.svg?react';
import RightarrowSimple from './icons/rightarrow-simple.svg?react';
import RubricIcon from './icons/rubric-icon.svg?react';
import Schools from './icons/schools.svg?react';
import ScrollAndCard from './icons/scroll-and-card.svg?react';
import Search from './icons/search.svg?react';
import Segment from './icons/segment.svg?react';
import Service from './icons/service.svg?react';
import SettingsIcon from './icons/settings-icon.svg?react';
import Share from './icons/share.svg?react';
import Show from './icons/show.svg?react';
import SideArrow from './icons/side-arrow.svg?react';
import SignatureQuestionIcon from './icons/signature-question-icon.svg?react';
import SingleProduct from './icons/single-product.svg?react';
import SingleSelectQuestionIcon from './icons/single-select-question-icon.svg?react';
import Singleselect from './icons/singleselect.svg?react';
import SmartLaunch from './icons/smart-launch.svg?react';
import Social from './icons/social.svg?react';
import Software from './icons/software.svg?react';
import Sort from './icons/sort.svg?react';
import Sso from './icons/sso.svg?react';
import StackedTiles from './icons/stacked-tiles.svg?react';
import StarSmall from './icons/star-small.svg?react';
import Statuses from './icons/instUI/statuses-icon.svg?react';
import Students from './icons/students.svg?react';
import Subscriptions from './icons/subscriptions.svg?react';
import Success from './icons/success.svg?react';
import SuccessIcon from './icons/successIcon.svg?react';
import Swap from './icons/swap.svg?react';
import TabletMac from './icons/tablet-mac.svg?react';
import Tag from './icons/instUI/tag-icon.svg?react';
import Tags from './icons/tags.svg?react';
import TagsIcon from './icons/tagsIcon.svg?react';
import TermsAndConditionsQuestionIcon from './icons/terms-and-conditions-question-icon.svg?react';
import Text from './icons/text.svg?react';
import TextExtension from './icons/text-extension.svg?react';
import ThumbNeutral from './icons/thumb-neutral.svg?react';
import ThumbNotRecommend from './icons/thumb-not-recommend.svg?react';
import ThumbRecommend from './icons/thumb-recommend.svg?react';
import ThumbDown from './icons/thumbDown.svg?react';
import ThumbUp from './icons/thumbUp.svg?react';
import ThumbsRatingQuestionIcon from './icons/thumbs-rating-question-icon.svg?react';
import TileColumns from './icons/tile-columns.svg?react';
import Trash from './icons/instUI/trash.svg?react';
import TrendingFlatIcon from './icons/trending-flat-icon.svg?react';
import Twitter from './icons/twitter.svg?react';
import Unlock from './icons/unlock.svg?react';
import Upload from './icons/instUI/upload.svg?react';
import Url from './icons/url.svg?react';
import UrlExtension from './icons/url-extension.svg?react';
import UserCircle from './icons/user-circle.svg?react';
import UserGear from './icons/user-gear.svg?react';
import Users from './icons/users.svg?react';
import Visibility from './icons/visibility.svg?react';
import VisibilityOff from './icons/visibilityOff.svg?react';
import WarningIcon from './icons/warning-icon.svg?react';
import WarningRound from './icons/warning-round.svg?react';
import WarningFill from './icons/warningFill.svg?react';
import Welcome from './icons/welcome.svg?react';
import WorkflowAction from './icons/workflow-action.svg?react';
import WorkflowIcon from './icons/workflow-icon.svg?react';
import WorkflowStart from './icons/workflow-start.svg?react';
import WorkflowUser from './icons/workflow-user.svg?react';
import Xls from './icons/xls.svg?react';
import Zoom from './icons/zoom.svg?react';

// Don't change the next line from 'export const ICONS = {'. Our icons lint rule looks for this.
export const ICONS = {
  Chromebook,
  'Desktop computer': DesktopComputer,
  'Interactive whiteboard': InteractiveWhiteboard,
  Laptop,
  'Lp-logo': LpLogo,
  'Lp-logo-Dark': LpLogoDark,
  'Mobile device': MobileDevice,
  Tablet,
  accept: Accept,
  accesscontrols: Accesscontrols,
  accessibility: Accessibility,
  'account-circle': AccountCircle,
  add: Add,
  'add-to-library': AddToLibrary,
  addImage: AddImage,
  addPhotoAlternate: AddPhotoAlternate,
  addPhotoPreview: AddPhotoPreview,
  admins: Admins,
  apple: Apple,
  'apple-logo': AppleLogo,
  'application-extension': ApplicationExtension,
  archive: Archive,
  'archive-icon': ArchiveIcon,
  'arrow-up-down': ArrowUpDown,
  assessment: Assessment,
  authentication: Authentication,
  'back-icon': BackIcon,
  badges: Badges,
  'brand-container': BrandContainer,
  'business-center': BusinessCenter,
  blog: Blog,
  canvasIntegrated: CanvasIntegrated,
  canvasConnect: CanvasConnect,
  calendar: Calendar,
  card: Card,
  chartBarSolid: ChartBarSolid,
  'check-icon': CheckIcon,
  'check-round': CheckRound,
  checkFill: CheckFill,
  checkRound: CheckRound2,
  'chevron-down': ChevronDown,
  'chevron-up': ChevronUp,
  chipClose: ChipClose,
  circle: Circle,
  'classlink-logo': ClasslinkLogo,
  clear: Clear,
  clearSearch: ClearSearch,
  'clever-logo': CleverLogo,
  clock: Clock,
  close: Close,
  closeButton: CloseButton,
  collapse: Collapse,
  columns: Columns,
  comment: Comment,
  commentHide: CommentHide,
  commentShow: CommentShow,
  compare: Compare,
  'compare-icon': CompareIcon,
  completed: Completed,
  congratulations: Congratulations,
  construction: Construction,
  content: Content,
  copy: Copy,
  copyUrl: CopyUrl,
  createCompany: CreateCompany,
  cube: Cube,
  dangerFill: DangerFill,
  data: Data,
  dataIcon: DataIcon,
  dataSourceCs: DataSourceCs,
  dataSourceIste: DataSourceIste,
  dataSourceJamf: DataSourceJamf,
  dataSourceNces: DataSourceNces,
  dataSourcePu: DataSourcePu,
  dataSourceSdpc: DataSourceSdpc,
  dataSourceSpp: DataSourceSpp,
  'date-question-icon': DateQuestionIcon,
  decline: Decline,
  desktop: Desktop,
  display: Display,
  districts: Districts,
  doc: Doc,
  docx: Doc, // alias
  done: Done,
  donut: Donut,
  download: Download,
  'dragdrop-horizontal': DragdropHorizontal,
  dragndrop: Dragndrop,
  'ds-calendar': DsCalendar,
  'ds-mytasks': DsMytasks,
  'ds-remove': DsRemove,
  'ds-tooltip': DsTooltip,
  duplicate: Duplicate,
  edit: Edit,
  edit2: Edit2,
  edu: Edu,
  educators: Educators,
  'ellipsis-icon': EllipsisIcon,
  email: Email,
  emptyState: EmptyState,
  emptyState10: EmptyState10,
  emptyState11: EmptyState11,
  emptyState2: EmptyState2,
  emptyState3: EmptyState3,
  emptyState4: EmptyState4,
  emptyState5: EmptyState5,
  emptyState6: EmptyState6,
  emptyState7: EmptyState7,
  emptyStateLaunch: EmptyStateLaunch,
  emptyStateNoLinks: EmptyStateNoLinks,
  essaLevel: EssaLevel,
  event: Event,
  existingCompany: ExistingCompany,
  expandLess: ExpandLess,
  expandMore: ExpandMore,
  export: Export,
  externalLink: ExternalLink,
  eye: Eye,
  facebook: Facebook,
  'facebook-logo': FacebookLogo,
  failedIcon: FailedIcon,
  'fast-forward': FastForward,
  'feedback-icon': FeedbackIcon,
  file: File,
  'file-extension': FileExtension,
  'file-outline': FileOutline,
  'file-upload-question-icon': FileUploadQuestionIcon,
  filter: Filter,
  filterClose: FilterClose,
  forgotPassword: ForgotPassword,
  forms: Forms,
  'forms-outline': FormsOutline,
  'free-text-question-icon': FreeTextQuestionIcon,
  gdrive: Gdrive,
  generic: Generic,
  gift: Gift,
  google: Google,
  grade: Grade,
  'graduation-cap': GraduationCap,
  groups: Groups,
  'gsuite-logo': GsuiteLogo,
  hardware: Hardware,
  'hourglass-icon': HourglassIcon,
  ignored: Ignored,
  image: Image,
  impersonate: Impersonate,
  incompleted: Incompleted,
  info: Info,
  info_italic: InfoItalic,
  'info-borderless': InfoBorderless,
  'inst-download': InstDownload,
  'inst-edit': InstEdit,
  'inst-eye-line': InstEyeLine,
  'eye-line-slash': EyeLineSlash,
  'inst-eye-filled': InstEyeFilled,
  'inst-notepad': InstNotepad,
  'inst-trash': InstTrash,
  'inst-info': InstInfo,
  'inst-gradebook': InstGradeBook,
  'inst-file': InstFile,
  invitation: Invitation,
  jpeg: Jpg, // alias
  jpg: Jpg,
  'keyboard-arrow-down': KeyboardArrowDown,
  'keyboard-arrow-left': KeyboardArrowLeft,
  'keyboard-arrow-right': KeyboardArrowRight,
  'keyboard-arrow-up': KeyboardArrowUp,
  'laptop-mac': LaptopMac,
  launch: Launch,
  'leftarrow-icon': LeftarrowIcon,
  'likert-scale-question-icon': LikertScaleQuestionIcon,
  list: List,
  lms: Lms,
  lock: Lock,
  locked: Locked,
  logo: Logo,
  'logo-circle': LogoCircle,
  'logo-solid': LogoSolid,
  'logo-white': LogoWhite,
  lpLogoColor: LpLogoColor,
  lpLogoBlack: LpLogoBlack,
  mail: Mail,
  mapped: Mapped,
  mapper: Mapper,
  mapperhub: Mapperhub,
  marketplace: Marketplace,
  markRound: MarkRound,
  memberships: Memberships,
  'menu-analysis': MenuAnalysis,
  'menu-community': MenuCommunity,
  'menu-dashboard': MenuDashboard,
  'menu-data': MenuData,
  'menu-gear': MenuGear,
  'menu-home': MenuHome,
  'menu-management': MenuManagement,
  'menu-members': MenuMembers,
  'menu-products': MenuProducts,
  'menu-settings': MenuSettings,
  'menu-subscriptions': MenuSubscriptions,
  'menu-sysadmin': MenuSysadmin,
  'microsoft-logo': MicrosoftLogo,
  minus: Minus,
  missingProduct: MissingProduct,
  'more-horiz': MoreHoriz,
  'multi-select-question-icon': MultiSelectQuestionIcon,
  'multiple-products': MultipleProducts,
  newProducts: NewProducts,
  'newwindow-icon': NewwindowIcon,
  'no-icon-placeholder': NoIconPlaceholder,
  'not-mapped': NotMapped,
  'not-mapped-disabled': NotMappedDisabled,
  notes: Notes,
  'notification-bell': NotificationBell,
  operational: Operational,
  organizations: Organizations,
  other: Other,
  'paper-clip': PaperClip,
  'paper-plane': PaperPlane,
  pdf: Pdf,
  people: People,
  pin: Pin,
  'placeholder-question-icon': PlaceholderQuestionIcon,
  plus: Plus,
  plug: Plug,
  plusRound: PlusRound,
  png: Png,
  ppl: Ppl,
  'pricing-question-icon': PricingQuestionIcon,
  'product-logo-placeholder': ProductLogoPlaceholder,
  productCustomPrivacyStatus: ProductCustomPrivacyStatus,
  productCustomStatus: ProductCustomStatus,
  products: Products,
  providers: Providers,
  public: Public,
  'quick-reference-all': QuickReferenceAll,
  'rating-digits-scale-question-icon': RatingDigitsScaleQuestionIcon,
  reference: Reference,
  refresh: Refresh,
  'remove-icon': RemoveIcon,
  remind: Remind,
  removeRedEye: RemoveRedEye,
  reply: Reply,
  resources: Resources,
  revert: Revert,
  'rightarrow-icon': RightarrowIcon,
  'rightarrow-simple': RightarrowSimple,
  'rubric-icon': RubricIcon,
  schools: Schools,
  'scroll-and-card': ScrollAndCard,
  search: Search,
  segment: Segment,
  service: Service,
  'settings-icon': SettingsIcon,
  share: Share,
  show: Show,
  'side-arrow': SideArrow,
  'signature-question-icon': SignatureQuestionIcon,
  'single-product': SingleProduct,
  'single-select-question-icon': SingleSelectQuestionIcon,
  singleselect: Singleselect,
  'smart-launch': SmartLaunch,
  social: Social,
  software: Software,
  sort: Sort,
  sso: Sso,
  'stacked-tiles': StackedTiles,
  'star-small': StarSmall,
  statuses: Statuses,
  students: Students,
  subscriptions: Subscriptions,
  success: Success,
  successIcon: SuccessIcon,
  swap: Swap,
  'tablet-mac': TabletMac,
  tag: Tag,
  tags: Tags,
  tagsIcon: TagsIcon,
  'terms-and-conditions-question-icon': TermsAndConditionsQuestionIcon,
  text: Text,
  'text-extension': TextExtension,
  'thumb-neutral': ThumbNeutral,
  'thumb-not-recommend': ThumbNotRecommend,
  'thumb-recommend': ThumbRecommend,
  thumbDown: ThumbDown,
  thumbUp: ThumbUp,
  'thumbs-rating-question-icon': ThumbsRatingQuestionIcon,
  'tile-columns': TileColumns,
  trash: Trash,
  'trending-flat-icon': TrendingFlatIcon,
  twitter: Twitter,
  unlock: Unlock,
  upload: Upload,
  url: Url,
  'url-extension': UrlExtension,
  'user-circle': UserCircle,
  'user-gear': UserGear,
  users: Users,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  'warning-icon': WarningIcon,
  'warning-round': WarningRound,
  warningFill: WarningFill,
  welcome: Welcome,
  'workflow-action': WorkflowAction,
  'workflow-icon': WorkflowIcon,
  'workflow-start': WorkflowStart,
  'workflow-user': WorkflowUser,
  xls: Xls,
  xlsx: Xls, // alias
  zoom: Zoom,
};

// take all the string keys in the ICONS object, make a string literal union type and export it for use elsewhere
export type Icon = keyof typeof ICONS;

interface Props extends React.SVGAttributes<SVGSVGElement> {
  icon: Icon;
}

const Icon = forwardRef<SVGSVGElement, Props>(({ icon, className, ...rest }: Props, ref) => {
  const IconComponent = ICONS[icon];
  if (!IconComponent) {
    // eslint-disable-next-line no-console
    console.warn(`Icon \`${icon}\` not found.`);
    return null;
  }
  return <IconComponent {...rest} ref={ref} className={cn(`-${icon}`, className)} />;
});

export default Icon;
